/* eslint-disable */
// Import the required function from mapsHelper.js
import { getMapsHelper } from "../util/mapsHelper.js";

export default class Map {
	constructor(mapSelector, locationElementSelector = "[data-map-coords]") {
		this.mapNode = document.querySelector(mapSelector);
		this.locationElementSelector = locationElementSelector;
		this.mapObj = null;
		this.markers = [];
		this.originalZoom = 16;

		this.init();
	}

	init() {
		if (!this.mapNode) {
			return;
		}

		console.log('here');
		this.sectionNode = this.mapNode.closest("section");

		const mapHelper = getMapsHelper();

		mapHelper.ready().then(() => {
			const theMap = mapHelper.createMap({
				element: this.mapNode,
				locationElementSelector: this.locationElementSelector,
				useRichmarker: true,
				markerReducer: (el) => {
					return {
						lat: el.getAttribute("lat"),
						lng: el.getAttribute("lng"),
						content: `
							<a 
								class="pin-marker" 
								href="${el.getAttribute("link")}" 
								target="${el.getAttribute("target")}"
								data-pin="${el.getAttribute("data-pin")}"
							>
								<img 
									class="pin-marker-icon"
									src="/dist/images/icons/map_pin.svg" 
									alt="Visit facility"
								>
								<span class="pin-marker-index">
									${el.getAttribute("data-pin")}
								</span>
							</a>
						`,
					};
				},
			});

			this.mapObj = theMap;
			google.maps.event.addListenerOnce(theMap, "idle", () => {
				if (document.querySelectorAll(".location-item").length < 2) {
					theMap.setZoom(this.originalZoom);
				}
			});
		});
	}

	addMarker(location, name) {
		const marker = new google.maps.Marker({
			position: location,
			map: this.mapObj,
			title: name,
		});

		this.markers.push(marker);
		this.fitMapToBounds();
		const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.lat()},${location.lng()}`;
		marker.addListener("click", () => {
			window.open(googleMapsUrl, "_blank");
		});
	}

	clearMarkers() {
		this.markers.forEach((marker) => {
			marker.setMap(null);
		});
		this.markers = [];
		this.mapObj.setZoom(this.originalZoom);
	}

	fitMapToBounds() {
		if (this.markers.length === 0) return;
		var bounds = new google.maps.LatLngBounds();
		this.markers.forEach((marker) => {
			bounds.extend(marker.getPosition());
		});

		this.mapObj.fitBounds(bounds);
	}
}
/* eslint-enable */