import { contactSubmit } from './contact.js';
import Overlay from "./services/Overlay.js";
import Slider from "./services/Slider.js";
import Map from "./services/Map.js";
import FilterUnits from './services/FilterUnits.js';
import './components/aiSelect.js';
import Contact from "./services/Contact.js";

if (document.getElementById('contact_form')) {
	contactSubmit();
}

/* eslint-disable */
document.addEventListener('DOMContentLoaded', () => {
	const overlay = new Overlay();
	const reviewsSlider = new Slider('[data-slider="reviews"]');
	// const singleFacilityMap = new Map('[data-map="section-map"]');
	const locationsMap = new Map('[data-map="locations-map"]');
	const facilitySlider = new Slider('[data-slider="facility-images"]');
	const filterUnits = new FilterUnits('[data-js="facility-rates-node"]');
	const findUsMap = new Map('[data-map="map-contact"]');
	const formContact = new Contact('[data-form="contact"]', [
		"name",
		"phone",
		"email",
		"message"]);

	// Toggle Mobile Menu
	(() => {
		const navOpenBtn = document.querySelector('[data-js="nav-open"]');
		const navCloseBtn = document.querySelector('[data-js="nav-close"]');
		const navMenu = document.querySelector('[data-js="nav-menu"]');

		if (!navMenu) {
			return;
		}

		if (navOpenBtn) {
			navOpenBtn.addEventListener("click", () => {
				navMenu.classList.add("active");
				overlay.show();
				overlay.addListenerOnClose(() => {
					navMenu.classList.remove("active");
				});
			});
		}

		if (navCloseBtn) {
			navCloseBtn.addEventListener("click", () => {
				navMenu.classList.remove("active");
				overlay.hide();
			});
		}
	})();

	//bodyu content redirection

	(() => {
		document.addEventListener('DOMContentLoaded', () => {
			const btn = document.querySelector('.h-cont-btn .btn');
			const classType = btn.getAttribute('data-class');

			btn.addEventListener('click', function (event) {
				const href = btn.getAttribute('href');
				console.log(btn, classType);
				if (classType === 'rates') {
					event.preventDefault();
					window.scrollTo({ top: 0, behavior: 'smooth' });
				} else {
					window.location.href = href;
				}
			});
		});
	})();


	//reeviews
	(() => {


		document.querySelectorAll('.read-more').forEach(button => {
			button.addEventListener('click', function () {
				const reviewText = this.getAttribute('data-message');
				const wrapperSlider = document.querySelector('.wrapper-slider');

				const reviewOverlay = document.createElement('div');
				reviewOverlay.className = 'review-overlay';

				const closeButton = document.createElement('div');
				closeButton.className = 'close-button';
				// Add your SVG or custom close icon here
				closeButton.innerHTML = 'X';

				const reviewContent = document.createElement('div');
				reviewContent.className = 'review-content';
				reviewContent.innerText = reviewText;

				reviewOverlay.appendChild(closeButton);
				reviewOverlay.appendChild(reviewContent);

				wrapperSlider.appendChild(reviewOverlay);

				setTimeout(() => {
					reviewOverlay.classList.add('visible');
				}, 10);

				closeButton.addEventListener('click', function () {
					reviewOverlay.classList.remove('visible');
					setTimeout(() => {
						reviewOverlay.remove();
					}, 300); // Match the transition duration
				});
			});
		});

	})();


}, true);
/* eslint-enable */